<template>
  <section v-if="!responded">
    <b-overlay :show="loading" rounded="sm">
      <b-card>
        <textarea
          class="mt-2 form-control textarea"
          v-model="body"
          :placeholder="$t('RESPONSE')"
          rows="4"
        ></textarea>

        <h4 class="mt-2">
          {{ $t("ATTACHMENTS") }}
          <FileSelect v-model="files">
            <b-button size="sm" variant="secondary">
              <i class="fal fa-plus"></i> {{ $t("ADD") }}
            </b-button>
          </FileSelect>
        </h4>

        <div v-if="files.length == 0">
          {{ $t("NONE") }}
        </div>

        <div v-if="files.length != 0" class="mt-2">
          <span
            v-for="(item, index) in files"
            :key="index"
            class="ms-fontSize-12"
          >
            <Attachment
              @removeFile="removeFile"
              :item="item"
              :index="index"
            ></Attachment>
          </span>
        </div>
        <template v-slot:footer>
          <b-button
            :disabled="!canSend"
            @click="sendResponse"
            variant="primary"
            class="btn-fill"
            >{{ $t("RESPOND") }}</b-button
          >
        </template>
      </b-card>
    </b-overlay>
  </section>
</template>
<script>
import * as blobUtil from "blob-util";
import FileSelect from "@/components/Compose/FileSelect";
import Attachment from "@/components/Compose/Attachment";
export default {
  props: ["message_uuid"],
  components: {
    FileSelect,
    Attachment,
  },
  data() {
    return {
      responded: false,
      files: [],
      attachments: [],
      loading: false,
      body: "",
      locale: this.$i18n.locale
   };
  },
  methods: {
    removeFile(index) {
      this.files.splice(index, 1);
    },
    async getFileContent(file)
    {
      return new Promise((resolve) => {
        blobUtil
          .blobToBase64String(file)
          .then(function(base64String) {
            resolve(base64String);       
          })
          .catch(function() {
            resolve(null);
          });
      });
    },
    async checkFiles() {
      this.attachments = [];
      for (let ix = 0; ix < this.files.length; ix++) {
        let base64Content = await this.getFileContent(this.files[ix]);
        if(base64Content == null)
        {
          this.$noty.error(self.$t("ERROR.EXCEPTION"));
          return false;
        }else{
          this.attachments.push({
            name: this.files[ix].name,
            type: this.files[ix].type,
            content: base64Content,
          });
        }
      }
      return true;
    },
    async sendResponse() {
      let self = this;
      self.loading = true;
      let iteratedFiles = await this.checkFiles();
      if(iteratedFiles == false){ return false; }
      this.$http
        .post(
          this.user.hostname + "/message/response",
          {
            body: this.body,
            response_to_message_uuid: this.message_uuid,
            attachments: this.attachments,
          }
        )
        .then(function(response) {
          self.$emit("responded", response.data.message_uuid);
          self.responded = true;
          self.loading = false;
        })
        .catch(function(error) {
          self.loading = false;
          if(error.toString() == "Error: fail_4_4") return;
          if (error.response.data != undefined) {
            self.$noty.error(self.$t(error.response.data));
          }else{
            self.$noty.error(self.$t("ERROR.COULD_NOT_SEND"));
          }
        });
    },
    bytesToSize(bytes, decimals = 2) {
      if (bytes === 0) return "0";
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
  },
  computed: {
    canSend() {
      if (this.body != "") {
        return true;
      }
      if (this.attachments.length != 0) {
        return true;
      }
      return false;
    },
  },
  mounted() {},
};
</script>
<style lang="css"></style>
